import React from 'react';
import './App.css';
import Avatar from "./components/avatar/Avatar";
import Footer from "./components/footer/Footer";

export default function App() {
    return (
        <div className="App">
            <Avatar/>
            <Footer/>
        </div>
    );
}

