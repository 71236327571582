import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <div className="Footer">
            <span className="Copyright">© Gábor Kökény - 1988 - 2024</span>
        </div>
    );
}
